import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {

  public currentYear = new Date().getFullYear();

  constructor() { }

  onLogoClick(): void {
    const urlEsolutions = 'http://www.enterprisesolutions.com.py/';
    window.open(urlEsolutions, '_blank');
  }

}