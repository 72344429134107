<div
  class="sm:bg-gradient-to-r sm:from-green-500 sm:via-green-600 sm:to-green-500 sm:p-9 sm:max-h-100% sm:max-w-100% h-screen md:h-full">
  <mat-card fxFlex>
    <mat-card-content class="w-full h-full" fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="50%">
        <div fxLayout="column" fxLayoutAlign="center center" class="min-h-full" *ngIf="parametrosLogin">
          <!-- LOGO EN RESPONSIVE -->
          <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxShow.lt-md fxHide.gt-sm>
            <div fxFlex fxFlexOffset="15">
              <img style="height: 150px;" src="assets/images/login/Coopeduc-logo.png" alt="Main Logo">
            </div>
          </div>
          <!-- END  LOGO EN RESPONSIVE -->
          <ng-template [ngIf]="show === 'inicio'">
            <div class="w-96">
              <form class="w-32" fxFlex fxLayout="column" [formGroup]="loginForm">
                <div fxFlex fxLayoutAlign="center center" fxHide.lt-md fxShow.gt-sm>
                  <img class="w-30 h-32" src="assets/images/login/Coopeduc-logo.png" alt="Main Logo">
                </div>
                <div class="text-center">
                  <h2 class="uppercase text-2xl font-sans font-extrabold">Iniciar sesión</h2>
                </div>
                <hr>
                <mat-form-field appearance="outline" class="mb-3 mt-3">
                  <mat-label *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'email'">Ingrese su email</mat-label>
                  <mat-label *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'doc'">Ingrese su documento</mat-label>
                  <mat-label *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'username'">
                    Ingrese su nombre de usuario
                  </mat-label>
                  <input type="text" matInput formControlName="username" required autocomplete="username">
                  <mat-hint *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'email'">
                    Nunca compartiremos su correo electrónico con nadie más.
                  </mat-hint>
                  <mat-hint *ngIf="parametrosLogin.LOGINTYPE.LOGINTYPE === 'doc'">
                    Nunca compartiremos su información con nadie más.
                  </mat-hint>
                  <mat-error
                    *ngIf="loginForm.controls.username.hasError('email') && !loginForm.controls.username.hasError('required')">
                    Por favor, ingrese una dirección de email valida.
                  </mat-error>
                  <mat-error
                    *ngIf="loginForm.controls.username.hasError('required') && parametrosLogin.LOGINTYPE.LOGINTYPE === 'email'">
                    El email es <strong>requerido</strong>
                  </mat-error>
                  <mat-error
                    *ngIf="loginForm.controls.username.hasError('required') && parametrosLogin.LOGINTYPE.LOGINTYPE === 'doc'">
                    El número de documento es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Contraseña</mat-label>
                  <input #passInput type="password" matInput autocomplete="current-password" formControlName="password"
                    id="password">
                  <mat-icon *ngIf="passInput.type === 'password'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick()">
                    visibility
                  </mat-icon>
                  <mat-icon *ngIf="passInput.type === 'text'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick()">
                    visibility_off
                  </mat-icon>
                </mat-form-field>
                <re-captcha class="mb-3 self-center" formControlName="recaptcha" lang="es"></re-captcha>
                <div fxLayout="column" fxLayoutGap="5%" fxLayoutAlign="center">
                  <div fxLayout="column" fxLayout.gt-xs="row" fxLayoutAlign="center">
                    <button mat-raised-button type="submit" color="primary" (click)="iniciarSesion()"
                      class="mt-2 sm:mt-0 mx-1 flex-1 text-white bg-gradient-to-r from-green-600 via-green-500 to-green-600 hover:bg-gradient-to-bl rounded-3xl
                      hover:shadow-xl focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm px-1 py-2.5 text-center">
                      <mat-icon>login</mat-icon> Ingresar
                    </button>
                    <button mat-raised-button type="button" (click)="changeVisual('forgot')"
                      class="flex-1 mt-2 sm:mt-0 mx-1 bg-yellow-400 hover:shadow-xl focus:ring-4 focus:outline-none rounded-3xl focus:ring-yellow-300 dark:focus:ring-yellow-800 font-medium text-sm px-1 py-2.5 text-center text-white">
                      <mat-icon class="mr-2">key_off</mat-icon>Olvidé mi contraseña
                    </button>
                  </div>
                  <div fxLayout="column" class="flex justify-center items-center mt-2 xl:mt-10" fxHide.lt-md>
                    <span class="text-xs fontResponsive">Powered By:</span>
                    <div fxLayout="row">
                      <a href="http://www.enterprisesolutions.com.py/" target="_blank">
                        <img src="/assets/Enterprise-logo.png" class="h-8 imgLogoEs"
                          alt="Powered by: Enterprise Solutions">
                      </a>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </ng-template>
          <ng-template [ngIf]="show === 'register'">
            <div fxFlex fxFlexOffset.gt-sm="5%">
              <h2>Regístrate</h2>
            </div>
            <app-register [minpassword]="parametrosLogin.MINPASSWORD.MINPASSWORD" (hide)="changeVisual('inicio')"
              [maxpassword]="parametrosLogin.MAXPASSWORD.MAXPASSWORD"></app-register>
          </ng-template>
          <ng-template [ngIf]="show === 'forgot'">
            <div class="text-center">
              <h2 class="uppercase text-2xl font-sans font-extrabold">Recuperar la cuenta</h2>
            </div>
            <app-olvidar-contrasenha (hide)="changeVisual('inicio')" [loginType]="parametrosLogin.LOGINTYPE.LOGINTYPE">
            </app-olvidar-contrasenha>
          </ng-template>
          <ng-template [ngIf]="show === 'recover'">
            <div fxFlex fxFlexOffset.gt-sm="20%">
              <h2>Cambiar contraseña</h2>
            </div>
            <app-recuperar-contrasenha (hide)="changeVisual('inicio')">
            </app-recuperar-contrasenha>
          </ng-template>
        </div>

      </div>
      <div fxFlex="50%" fxHide.lt-md>
        <div fxLayout="column" class="h-full">

          <div fxFlex fxLayoutAlign="center center" class="h-40 xl:h-54">
            <img class="h-50 loginResponsive" src="assets/images/login/Coopeduc-logo.png" alt="Main Logo">
          </div>

          <div fxFlex class="bg-gradient-to-r from-green-500 via-green-400 to-green-500 h-20" fxLayout="column"
            fxLayoutAlign="center center" fxHide.lt-md>
            <img src="assets/images/login/Frame.png" alt="Frame Almacen" class="h-80 xl:h-full"
              style="position: relative; bottom: 200px; top: -100px" fxHide.lt-md>
            <div fxLayout="row" style="position: relative; top: -120px">
              <p class="uppercase text-2xl text-white font-mono font-bold">Sistema De Almacén Coopeduc</p>
            </div>
          </div>

        </div>

      </div>
    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="isLoading$ | async" class="loading-container">
  <mat-progress-spinner class="spinner" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>