<form [formGroup]="forgotPassForm">
  <div fxLayout="column" fxLayoutAlign="space-around space-between" fxLayoutGap="5%">
    <mat-form-field *ngIf="loginType === 'email'" appearance="outline" fxFlex>
      <mat-label>Ingrese su email</mat-label>
      <input id="user" type="email" placeholder="Ingrese su email" aria-required="true" matInput formControlName="email"
        [required]="loginType === 'email'">
      <mat-hint class="mt-1 mb-0"> Nunca compartiremos su correo electrónico con nadie más.</mat-hint>
      <mat-error
        *ngIf="forgotPassForm.controls.email.hasError('email') && !forgotPassForm.controls.email.hasError('required')">
        Por favor, ingrese una dirección de email valida.
      </mat-error>
      <mat-error *ngIf="forgotPassForm.controls.email.hasError('required')">
        El email es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="loginType === 'doc'" appearance="outline" fxFlex>
      <mat-label>Ingrese su número de documento</mat-label>
      <input id="user2" type="text" placeholder="Número de documento..." aria-required="true" matInput
        formControlName="doc" [required]="loginType === 'doc'">
      <mat-hint class="mt-1 mb-0"> Nunca compartiremos sus datos con nadie más.</mat-hint>
      <mat-error *ngIf="forgotPassForm.controls.doc.hasError('required')">
        El número de documento es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="loginType === 'username'" appearance="outline" fxFlex>
      <mat-label>Ingrese su nombre de usuario</mat-label>
      <input id="user2" type="text" placeholder="Nombre de usuario" aria-required="true" matInput
        formControlName="username" [required]="loginType === 'username'">
      <mat-hint class="mt-1 mb-0"> Nunca compartiremos sus datos con nadie más.</mat-hint>
      <mat-error *ngIf="forgotPassForm.controls.username.hasError('required')">
        El nombre de usuario <strong>requerido</strong>
      </mat-error>
    </mat-form-field>

    <div fxLayout="row" fxLayoutGap="6%" class="mt-5">
      <button fxFlex type="submit" mat-raised-button color="primary" (click)="enviarEmail()"
        [disabled]="!forgotPassForm.valid">Enviar
      </button>
      <button fxFlex type="button" mat-raised-button (click)="[forgotPassForm.reset(), hide.emit(true)]">Cancelar
      </button>
    </div>
  </div>
</form>