import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


export interface ErrorResponse {
  success: boolean;
  data: string[];
  errors: string[];
  metadata: any;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) { }

  handleError(error: any) {
    if (error.status === 409 || error.status === 422 || error.status === 500) {
      const mensaje: ErrorResponse = error.error;
      if (mensaje.errors && mensaje.errors.length >= 0) {
        mensaje.errors.forEach((mensajeError: any) => {
          this.toastr.error(mensajeError, 'Atención');
        });
      } else {
        if (mensaje.message) {
          this.toastr.error(mensaje.message, 'Atención');
        } else {
          this.toastr.error('Respuesta de servidor no esperada. Contacte con el administrador', 'Atención',
          );
        }
      }
    } else if (error.status === 400) {
      console.log('Token vencido');
      this.toastr.warning('La sesión expiró, por favor, ingrese de nuevo', 'Atención');
    } else if (error.status === 403) {
      this.toastr.info('Acción bloqueada. El usuario logueado no tiene permisos para realizar lo solicitado, contacte con el administrador.', 'Atención');
    }
    else {
      this.toastr.error('Se produjo un error en el sistema. Revise los mensajes de alerta o contacte con el administrador', 'Atención');
    }
  }
}
