<div
  class="sm:bg-gradient-to-r sm:from-green-500 sm:via-green-600 sm:to-green-500 sm:p-9 sm:max-h-100% sm:max-w-100% h-max md:h-full">
  <mat-card fxFlex>
    <mat-card-content class="w-full h-full" fxLayout="row" fxLayout.lt-md="column">
      <div fxFlex="50%">
        <ng-container *ngIf="cargando === false">
          <div fxLayout="column" fxLayoutAlign="center center" class="min-h-full" *ngIf="parametrosIniciales">
            <!-- LOGO EN RESPONSIVE -->
            <div fxFlex fxLayout="column" fxLayoutAlign="center center" fxShow.lt-md fxHide.gt-sm>
              <div fxFlex fxFlexOffset="15">
                <img style="height: 150px;" src="assets/images/login/Coopeduc-logo.png" alt="Main Logo">
              </div>
            </div>
            <!-- END  LOGO EN RESPONSIVE -->
            <form [formGroup]="recuperarForm" *ngIf="recuperarForm">
              <div fxLayout="column" fxLayoutAlign="space-around space-between" fxLayoutGap="8%">
                <div class="text-center">
                  <p class="uppercase text-2xl font-sans font-bold">Recuperar contraseña</p>
                </div>
                <mat-form-field appearance="outline" fxFlex>
                  <mat-label>Dirección de email</mat-label>
                  <input matInput type="email" autocomplete="email" required formControlName="email">
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex>
                  <mat-label>Contraseña nueva</mat-label>
                  <input #first id="password" matInput type="password" required formControlName="password"
                    autocomplete="new-password">
                  <mat-icon *ngIf="first.type === 'password'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick('first')">
                    visibility
                  </mat-icon>
                  <mat-icon *ngIf="first.type === 'text'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick('first')">
                    visibility_off
                  </mat-icon>
                  <mat-hint>
                    La contraseña debe tener una longitud mínima de {{parametrosIniciales.MINPASSWORD.MINPASSWORD}}
                    caracteres
                    y un maximo de {{parametrosIniciales.MAXPASSWORD.MAXPASSWORD}} caracteres.
                  </mat-hint>
                  <mat-error
                    *ngIf="recuperarForm.get('password').hasError('minlength') && recuperarForm.get('password').touched">
                    Debe introducir al menos {{parametrosIniciales.MINPASSWORD.MINPASSWORD}} caracteres.
                  </mat-error>
                  <mat-error
                    *ngIf="recuperarForm.get('password').hasError('maxlength') && recuperarForm.get('password').touched">
                    Debe introducir sólo hasta {{parametrosIniciales.MAXPASSWORD.MAXPASSWORD}} caracteres.
                  </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex class="mt-3">
                  <mat-label>Repetir contraseña nueva</mat-label>
                  <input #second matInput id="password2" type="password" autocomplete="new-password"
                    formControlName="password_confirmation" required>
                  <mat-icon *ngIf="second.type === 'password'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick('second')">
                    visibility
                  </mat-icon>
                  <mat-icon *ngIf="second.type === 'text'" style="cursor: pointer;" matSuffix
                    (click)="onEyePasswordClick('second')">
                    visibility_off
                  </mat-icon>
                </mat-form-field>
                <div fxLayout="row" fxLayoutAlign="center">
                  <hr fxFlex="60%">
                </div>
                <div fxLayout="row" fxLayoutGap="2%">
                  {{recuperarForm.errors}}
                  <button fxFlex type="submit" mat-raised-button class="bg-green-500 text-white hover:bg-green-600"
                    (click)="actualizarPassword()" [disabled]="!recuperarForm.valid">
                    Actualizar
                  </button>
                </div>
              </div>
            </form>
            <div fxLayout="column" class="flex justify-center items-center mt-10" fxHide.lt-md>
              <span class="text-xs fontResponsive">Powered By:</span>
              <div fxLayout="row">
                <a href="http://www.enterprisesolutions.com.py/" target="_blank">
                  <img src="/assets/Enterprise-logo.png" class="h-8 imgLogoEs" alt="Powered by: Enterprise Solutions">
                </a>
              </div>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="cargando">
          <div fxLayout="column" fxLayoutAlign="center center" class="min-h-full">
            <mat-progress-spinner class="spinner" color="primary" mode="indeterminate">
            </mat-progress-spinner>
          </div>
        </ng-container>
      </div>
      <div fxFlex="50%" fxHide.lt-md>
        <div fxLayout="column" class="h-full">
          <div fxFlex fxLayoutAlign="center center" class="h-40 xl:h-54">
            <img class="h-50 loginResponsive" src="assets/images/login/Coopeduc-logo.png" alt="Main Logo">
          </div>
          <div fxFlex class="bg-gradient-to-r from-green-500 via-green-400 to-green-500 h-20" fxLayout="column"
            fxLayoutAlign="center center" fxHide.lt-md>
            <img src="assets/images/login/Frame.png" alt="Frame Almacen" class="h-80 xl:h-full"
              style="position: relative; bottom: 200px; top: -100px" fxHide.lt-md>
            <div fxLayout="row" style="position: relative; top: -120px">
              <p class="uppercase text-2xl text-white font-mono font-bold">Sistema De Almacén Coopeduc</p>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>