import { createReducer, on } from '@ngrx/store';
import { StartLoading, StopLoading, Reset } from './counter.actions';

export const initialState = false;

// tslint:disable-next-line:variable-name
const _counterReducer = createReducer(
  initialState,
  on(StartLoading, (state) => true),
  on(StopLoading, (state) => false),
  on(Reset, (state) => false)
  /*
  on(StartLoading, (state) => state + 1),
  on(StopLoading, (state) => state - 1),
  on(Reset, (state) => 0)
   */
);

export function counterReducer(state: any, action: any): any {
  return _counterReducer(state, action);
}
