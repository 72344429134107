<div class="mb-5 mt-4 border-1 border-gray-300 shadow-xl bg-white dark:bg-gray-900 rounded-xl">
  <p class="text-3xl font-extrabold tracking-normal hover:text-blue-500 dark:text-white text-black p-3 px-4">
    <mat-icon class="mt-2" [inline]="true" *ngIf="showIcon">
      {{icon}}
    </mat-icon>
    {{heading}}
  </p>
  <!-- <mat-card-subtitle *ngIf="subheading">{{subheading}}</mat-card-subtitle> -->
  <hr *ngIf="showButton"> <!-- si pongo dentro del div de abajo no aparece😡 -->
  <div class="flex items-start mt-2 p-3 px-4" *ngIf="showButton">
    <button
      class="py-4 px-5 h-10 w-auto inline-flex items-center justify-center text-center text-base bg-green-600 text-white hover:bg-opacity-90 font-normal rounded-full"
      (click)="clicked.emit(true)">
      <mat-icon>add</mat-icon>
      Nuevo registro
    </button>
  </div>
</div>