import { Injectable } from '@angular/core';
import { User } from '../../util/interfaces/user/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoginService } from '../../login/services/login.service';
import { LocalStorageService } from '../../util/services/local-storage/local-storage.service';
import { Router } from '@angular/router';
import { ErrorService } from '../../util/services/error-service/error.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private usuario: User;
  private loggedIn = new BehaviorSubject<boolean>(false);

  constructor(private loginService: LoginService, private localStorageService: LocalStorageService, private toastr: ToastrService,
    private router: Router, private errorService: ErrorService, private ngxPermissions: NgxPermissionsService) {
  }

  get isLoggedIn() {
    this.usuario = this.localStorageService.getUserFromStorage();
    if (this.usuario !== undefined && this.usuario !== null) {
      return true;
    }
    return false;

  }

  public renewAccessToken() {
    return this.loginService.refreshToken(this.localStorageService.getRefreshTokenFromStorage());
  }

  setToken(newToken: string) {
    this.localStorageService.setToken(newToken);
  }

  setRefreshToken(newToken: string) {
    this.localStorageService.setRefreshToken(newToken);
  }


  goToLogin() {
    this.localStorageService.cleanStorage();
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  public cargarPermisos(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let misPermisos: string[] = [];
      this.loginService.refrescarPermisos().subscribe((response: any) => {
        if (response.success) {
          misPermisos = response.data;
          this.ngxPermissions.loadPermissions(misPermisos);
        }
      }, (error: any) => {
        this.errorService.handleError(error);
        reject(false);
      }, () => {

        resolve(true);
      });
    });
  }

  login(usuario: User, redirectUrl: string = null) {
    if (usuario !== undefined) {
      this.localStorageService.storeOnLocalStorage(usuario);
      this.parametrosPorDefecto();

      if (redirectUrl == null) {
        this.goToHome(usuario);
      } else {
        this.router.navigate([redirectUrl]).catch(this.handleError.bind(this, usuario));
      }
    }
  }

  private goToHome(usuario: User) {
    this.loggedIn.next(true);
    this.router.navigate(['home']);
  }

  parametrosPorDefecto() {
    this.loginService.parametrosPorDefecto()
      .subscribe((datos: any) => {
        this.localStorageService.storeParametros(datos.data);
      },
        (error: any) => {
          this.errorService.handleError(error);
        },
        () => {
          const dias_expiracion = this.localStorageService.getParametrosFromStorage().CAMBIOCONTRA;
          if (this.usuario.userData.dias_expiracion <= dias_expiracion && this.usuario.userData.dias_expiracion !== 0) {
            this.toastr.error(`Tiene que cambiar la contraseña en los próximos ${this.usuario.userData.dias_expiracion} días obligatoriamente`
              , 'ATENCIÓN', {
              closeButton: true,
              tapToDismiss: false,
              timeOut: 10000 // 10 segundos de advertencia
            });
          }
        });
  }

  public handleError = (error: any, usuario: User) => {
    this.goToHome(usuario);
    return Observable.throw(error);
  }

  logout() {
    this.usuario = this.localStorageService.getUserFromStorage();
    if (this.usuario == null) {
      this.router.navigate(['/login']);
      return;
    } else {
      this.loginService.logoutUsuario()
        .subscribe((datos: any) => {
          this.localStorageService.cleanStorage();
          this.ngxPermissions.flushPermissions();
        },
          (error: any) => {
            this.errorService.handleError(error);
          }, () => {
            this.goToLogin();
          });
    }
  }
}
