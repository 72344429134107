import { EventEmitter, Input } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-page-filter',
  templateUrl: './page-filter.component.html',
  styleUrls: ['./page-filter.component.sass']
})
export class PageFilterComponent implements OnInit {

  file: any;
  @Output() clickEvent = new EventEmitter();
  @Output() clickEventCreate = new EventEmitter();
  @Output() clickPDF = new EventEmitter();
  @Output() clickExcel = new EventEmitter();
  @Output() clickCSV = new EventEmitter();
  @Output() clickUploading = new EventEmitter();
  @Input() mostrarSubir: boolean;
  @Input() mostrarDescargar: boolean;
  @Input() mostrarCrear: boolean = false;
  @Input() ocultarFiltro: boolean;
  @Input() textoDescargar: string = 'Descargar';
  @Input() textoCrear: string = 'Agregar';
  @Input() textoSubir: string = 'Subir';
  @Input() ocultarPDF: boolean;
  @Input() ocultarXLS: boolean;
  @Input() ocultarCSV: boolean;
  constructor() { }

  ngOnInit() {
  }

  filtrar(): void {
    this.clickEvent.emit(true);
  }

  reset(): void {
    this.clickEvent.emit(false);
  }

  pdf(): void {
    this.clickPDF.emit(true);
  }

  csv(): void {
    this.clickCSV.emit(true);
  }

  excel(): void {
    this.clickExcel.emit(true);
  }

  uploadFile(): void {
    this.clickUploading.emit(true);
  }
}
