import { UserSettingsComponent } from './header/user-settings/user-settings.component';
import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { LocalStorageService } from '../util/services/local-storage/local-storage.service';
import { Globals } from '../Globals';
import { User } from '../util/interfaces/user/user';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../auth/services/auth.service';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.sass']
})
export class BaseLayoutComponent implements OnInit, AfterViewChecked {

  @ViewChild('sidebar') sidebar: ElementRef<HTMLElement>;
  @ViewChild('drawer') drawer: ElementRef<HTMLElement>;
  mode: `over`;
  usuario: User;
  isMenuOpen: boolean = true;
  isDark = true;
  innerWidth: number;
  imageToShowDialog: string;
  showSidebar: boolean;

  constructor(
    private cdRef: ChangeDetectorRef,
    public localStorageService: LocalStorageService,
    private globals: Globals,
    private render: Renderer2,
    private dialog: MatDialog,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 1200) {
      this.showSidebar = false
    } else {
      this.showSidebar = true
    }
    this.getUsuario();
  }

  clickShowSidebar() {
    this.drawer.nativeElement.click()
    this.showSidebar = true;
  }

  clickCloseSidebar() {
    this.drawer.nativeElement.click()
    this.showSidebar = false;
  }

  getUsuario(): void {
    this.usuario = this.localStorageService.getUserFromStorage();
    if (this.usuario.userData.foto_perfil) {
      this.imageToShowDialog = environment.FILE_ENDPOINT + this.usuario.userData.foto_perfil;
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  getSystemVersion(): string {
    return this.globals.getSystemVersion();
  }

  openSettings(): void {
    this.dialog.open(UserSettingsComponent, {
      autoFocus: false,
      width: '55vw',
    })
  }

  logout(): void {
    this.authService.logout();
  }

  // Resize para el SideBar!
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = event.target.innerWidth;
    if (this.innerWidth < 1200) {
      this.showSidebar = false
    } else {
      this.showSidebar = true
    }
  }

}