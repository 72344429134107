import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Globals } from '../../Globals';
import { environment } from '../../../environments/environment';
import { ParametrosLoginResponse } from '../interfaces/login-responses';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private httpClient: HttpClient, private global: Globals) {
  }

  loginUsuario(usuario: string, password: string, application: string, recaptcha: string) {
    const body = 'username=' + usuario + '&password=' + password + '&application=' + application + '&g-recaptcha-response='+recaptcha;

    const headers = new HttpHeaders(
      { 'Content-Type': 'application/x-www-form-urlencoded' }
    );

    return this.httpClient.post(environment.API_ENDPOINT_ES + '/login', body, { headers });
  }

  logoutUsuario() {
    const headers = new HttpHeaders(
      {
        Accept: 'application/json'
      });
    const url = `${environment.API_ENDPOINT_ES}/logout`;
    return this.httpClient.post(url, null, { headers });
  }

  /*servicio que solicita los permisos del usuario logueado*/
  refrescarPermisos() {
    const headers = this.global.getHeader();
    const url = `${environment.API_ENDPOINT_ES}/misPermisos`;
    return this.httpClient.get(url, { headers });
  }

  parametrosPorDefecto() {
    const headers = this.global.getHeader();
    const url = `${environment.API_ENDPOINT_ES}/parametrosDefault`;
    return this.httpClient.get(url, { headers });
  }

  refreshToken(token: string) {
    const body = 'refresh_token=' + token + '&application=app1';

    const headers = new HttpHeaders(
      { 'Content-Type': 'application/x-www-form-urlencoded' }
    );

    return this.httpClient.post(`${environment.API_ENDPOINT_ES}/refreshToken`, body, { headers });
  }
  recuperarPassword(form: FormData) {
    const headers = new HttpHeaders(
      { 'Content-Type': 'application/json' }
    );
    const url = `${environment.API_ENDPOINT_ES}/passwords`;
    return this.httpClient.put(url, JSON.stringify(form), { headers });
  }

  getParametrosLogin() {
    const headers = this.global.getHeader();
    const url = `${environment.API_ENDPOINT_ES}/parametrosLogin`;
    return this.httpClient.get<ParametrosLoginResponse>(url, { headers });
  }

  enviarSolicitudCambioContrasenha(jsonData: string) {
    const headers = this.global.getHeader();
    const url = `${environment.API_ENDPOINT_ES}/passwords`;
    return this.httpClient.post(url, jsonData, { headers });
  }

  verificarTokenChangePassword(token: string) {
    const headers = this.global.getHeader();
    const url = `${environment.API_ENDPOINT_ES}/passwords/${token}`;
    return this.httpClient.get(url, { headers });
  }

  registrarUsuario(jsonData: string) {
    const headers = this.global.getHeader();
    const url = `${environment.API_ENDPOINT_ES}/register`;
    return this.httpClient.post(url, jsonData, { headers })
  }

  changePassword(password: string, token: string) {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Bearer ' + token
      });
    const url = `${environment.API_ENDPOINT_ES}/usuarios/changePasswords`;
    return this.httpClient.put(url, password, { headers });
  }
}
