<div fxLayout="row" fxLayoutGap="1%" fxLayout.lt-sm="column" class="flex items-start">
        <button type="button" (click)="volver()" *ngIf="editable && !isModal"
                class="px-6 pt-2.5 pb-2 bg-red-600 text-white font-medium text-sm leading-normal shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out flex align-center justify-center text-center rounded-full">
                <span class="material-icons">arrow_back</span>
                <span class="mt-1 ml-1">Volver</span>
        </button>
        <button type="button" (click)="editar()" *ngIf="editable" [disabled]="!showEditarButton"
                class="ml-2 px-6 pt-2.5 pb-2 bg-green-600 text-white font-medium text-sm leading-normal shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out flex align-center justify-center text-center rounded-full">
                <span class="material-icons">edit</span>
                <span class="mt-1 ml-1">Editar</span>
        </button>
        <button type="button" (click)="cerrar()" *ngIf="editable && isModal"
                class="px-6 pt-2.5 pb-2 bg-red-600 text-white font-medium text-sm leading-normal shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out flex align-center justify-center text-center rounded-full">
                <span class="material-icons">close</span>
                <span class="mt-1 ml-1">Cerrar</span>
        </button>
        <button type="button" (click)="actualizar()" [disabled]="process || !valid" *ngIf="!editable"
                [ngClass]="{'opacity-25': process || !valid, 'cursor-not-allowed' : process || !valid}"
                class="px-6 pt-2.5 pb-2 bg-green-600 text-white font-medium text-sm leading-normal shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out flex align-center justify-center text-center rounded-full">
                <span class="material-icons" *ngIf="!process">refresh</span>
                <span class="material-icons animate-spin" *ngIf="process">refresh</span>
                <span class="mt-1 ml-1">{{buttonTextActualizar}}</span>
        </button>
        <button type="button" (click)="cancelar()" *ngIf="!editable" [disabled]="process"
                class="px-6 pt-2.5 pb-2 bg-red-600 text-white font-medium text-sm leading-normal shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out flex align-center justify-center text-center rounded-full">
                <span class="material-icons">close</span>
                <span class="mt-1 ml-1">Cancelar</span>
        </button>
</div>