<div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="1%" class="mt-2">
  <div fxFlex fxLayout="row" fxLayoutGap="1%" fxLayout.lt-sm="column">
    <ng-template [ngIf]="!ocultarFiltro">
      <button (click)="filtrar()" type="submit" title="Buscar" class="px-5 inline-flex items-center justify-center text-center 
      text-black text-base border-none rounded-md bg-gray-200 w-auto h-10 hover:bg-green-700 hover:border-white hover:text-white
      transition">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
      </button>
      <button type="button" (click)="reset()" title="Limpiar Filtros" class="px-5 inline-flex items-center justify-center text-center 
      text-black text-base border-none rounded-md bg-gray-200 w-auto h-10 hover:bg-red-500 hover:border-white hover:text-white
      transition">
        <mat-icon>clear</mat-icon>
        Limpiar
      </button>
    </ng-template>
  </div>
  <div fxFlex fxLayout="row" fxLayoutAlign.gt-xs="end center" fxLayout.lt-sm="column-reverse" fxLayoutGap="1%">
    <button mat-flat-button (click)="uploadFile()" *ngIf="mostrarSubir">
      <mat-icon>cloud_upload</mat-icon>
      {{textoSubir}}
    </button>
    <button mat-flat-button [matMenuTriggerFor]="menuDownload" *ngIf="mostrarDescargar">
      <mat-icon>cloud_download</mat-icon>
      {{textoDescargar}}
    </button>
    <mat-menu #menuDownload="matMenu">
      <button mat-menu-item (click)="pdf()">PDF</button>
      <button mat-menu-item (click)="excel()">XLSX</button>
      <button mat-menu-item (click)="csv()">CSV</button>
    </mat-menu>
    <button mat-raised-button color="primary" (click)="clickEventCreate.emit(true)"
      *ngIf="mostrarCrear">{{textoCrear}}</button>
  </div>
</div>