import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { Globals } from 'src/app/Globals';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private global: Globals, private http: HttpClient) { }

  getPerfil(): Observable<any> {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/profile/users', null, null);
    return this.http.get(url, {headers});
  }

  actualizarContrasenia(jsonData: string): Observable<any> {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/profile/changesPasswords', null, null);
    return this.http.put(url, jsonData, {headers});
  }

  actualizarDatoPerfil(jsonData: string) {
    const headers = this.global.getHeader();
    const url = this.global.makeUrl('/profile/users', null, null);
    return this.http.post(url, jsonData, {headers});
  }

  editarUsuarioFoto(formData: FormData) {
    const url = this.global.makeUrl('/files', null, null);
    return this.http.post(url, formData);
  }

}